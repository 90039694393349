import he from 'he'

export function titleCase(s: string) {
  s = s.replaceAll('-', ' ')
  // title case each word in s
  return s.replace(
    /\w\S*/g,
    (w) => w.charAt(0).toUpperCase() + w.substr(1).toLowerCase()
  )
}

export function truncate(text: string, length: number): string {
  const clamp = '...'
  return text.length > length ? text.slice(0, length) + clamp : text
}

export function stripHtml(value?: string | null): string | undefined {
  if (value === undefined || value === null) {
    return undefined
  }

  return he.decode(value.replaceAll(/<\/?[^>]+(>|$)/g, ''))
}
